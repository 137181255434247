<template>
  <KModal
    :show.sync="showModal"
    :no-close-on-backdrop="true"
    :centered="true"
    title="Create Device Dialog"
    color="info"
  >
    <template #header>
      <h6 class="modal-title text-center">디바이스 {{title}}</h6>
    </template>

    <div class='modal-body'>
      <form name='userForm'>
        <div class='form-group'>
          <div class='row'>
            <div class='col-md-12'>
              <label>디바이스명 <strong class='text-danger'>*<small v-show='!isEditMode'>#번호 입력</small></strong></label>
              <input type='text' class='form-control' placeholder='디바이스명' v-model='device.name'>
            </div>
          </div>
        </div>
        <div class='form-group' v-show="!isEditMode">
          <div class='row'>
            <div class='col-md-12'>
              <label>주소 <strong class='text-danger'></strong></label>
              <input type='text' class='form-control' placeholder='주소' v-model='device.address' required>
            </div>
          </div>
        </div>
        <div class='form-group' v-show="!isEditMode">
          <label>데이터 업로드 주기 <small>0 0,8,16 * * * [분 시 일 * *]</small></label>
          <input type='text' id='input_cron' class='form-control' v-model='device.upload_cron'>
        </div>

        <div class='form-group' v-show="!isEditMode">
          <div class='row'>
            <div class='col-md-12'>
              <label>서비스 종류</label>
              <select class='form-control' style='display:inline-block;height:32px' v-model='device.service_type'>
                <option v-for="service in serviceTypes" :value="service.name" v-bind:key="service.name">{{service.name}} </option>
              </select>
            </div>
          </div>
        </div>
        
        <div class='form-group'>
          <div class='row'>
            <div class='col-md-12'>
              <label>네트워크 종류 <small>NB-IoT | Cat.M1 | LoRa | None</small></label>
              <select class='form-control' style='display:inline-block;height:32px' v-model='device.network_type'>
                <option v-for="network in networkTypes" :value="network.name" v-bind:key="network.name">{{network.name}} </option>
              </select>
            </div>
          </div>
        </div>

        <div class='form-group' v-show="isEditMode">
          <div class='row'>
            <div class='col-md-12'>
              <label><strong class='text-danger'>디바이스가 속한 사이트 변경 *</strong></label>
            </div>
          </div>
          <div class='row'>
            <div class='col-md-12'>
              <select class='form-control' style='display:inline-block;height:32px' v-model='device.site_guid'>
                <option v-for="site in sites" :value="site.guid" v-bind:key="site.guid">{{site.name}} </option>
              </select>
            </div>
          </div>
        </div>
      </form>
    </div>

    <template #footer>
      <CButton @click="register()" color="info">등록</CButton>
      <CButton @click="cancel()" color="light">취소</CButton>
    </template>

    <Notify ref="notifyDialog"/>

  </KModal>
</template>

<script>
import Vue from 'vue';
import loopback from '@/services/loopback';
import { SERVICE_TYPES, NETWORK_TYPES } from '@/constants/siteSettings'

export default {
  name: 'CreateDevice',
  props: {
    isAdmin: Boolean
  },
  data () {
    return {
      showModal: false,
      title: '생성',
      which: 'create',
      device: {},
      sites: [],
      serviceTypes: SERVICE_TYPES,
      networkTypes: NETWORK_TYPES,
      isEditMode: false
    }
  },
  methods: {
    createDevice() {
      Vue.$log.debug('CreateDevice::createDevice: ', this.device)
      this.device.deviceid="SN-";
      this.device.service_type="sis";
      this.device.site_guid=this.$parent.site_guid;
      this.device.user_guid=this.$parent.user.guid;
      this.device.latitude="37.5988194";
      this.device.longitude="127.0791566";

      var where = {
        name: this.device.name,
        address: this.device.address
      }

      loopback
        .upsertWithWhere('/devices', where, this.device)
        .then((device) => {
          let msg = {
            name: this.$options.name,
            which: this.which,
            data: device
          }
          this.$emit('hide', msg)
          this.showModal = false
        })
        .catch((err) => {
          Vue.$log.error('createDevcie error: ', err.toString())
          this.$refs.notifyDialog.show(err.toString())
        })
    },
    updateDevice() {
      Vue.$log.debug('CreateDevice::updateDevice: ', this.device)
      var data = {
        site_guid: this.device.site_guid,
        network_type: this.device.network_type
      }
      var where = {
        guid: this.device.guid
      }

      loopback
        .upsertWithWhere('/devices', where, data)
        .then((device) => {
          let msg = {
            name: this.$options.name,
            which: this.which,
            data: device
          }
          this.$emit('hide', msg)
          this.showModal = false
        })
        .catch((err) => {
          Vue.$log.error('createSite error: ', err.toString())
          this.$refs.notifyDialog.show(err.toString())
        })
    },
    register() {
      this.which === 'create' ? this.createDevice() : this.updateDevice();
    },
    cancel() {
      let msg = {
        name: this.$options.name,
        which: this.which,
        data: 'cancel'
      }
      this.$emit('hide', msg);
      this.showModal = false;
    },
    show(device, sites) {
      Vue.$log.debug('CreateDevice::show : ', JSON.stringify(device,null,2))
      this.device = device;
      this.sites = sites;
      this.title = _.isEmpty(this.device) ? '생성' : '변경'
      this.which = _.isEmpty(this.device) ? 'create' : 'update'
      this.isEditMode = !_.isEmpty(this.device)
      this.showModal = true
    }
  }
}
</script>
